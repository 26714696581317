export const tcvLinks = {
    default: 'https://cypherverse.io',
    voxodeus: 'https://voxodeus.io',
    cdl: 'https://cdlounge.io/',
    twitterVoxo: 'https://twitter.com/voxodeus',
    discordVoxo: 'https://discord.gg/voxodeus',
    twitterCdl: 'https://twitter.com/cdlounge',
    discordCdl: 'https://discord.gg/cdlounge',
    terms: 'https://terms.cdlounge.io',
    privacy: 'https://privacy.cdlounge.io',
    twitterCypherverse: 'https://twitter.com/thecypherverse'
}