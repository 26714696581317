import './App.scss';
import bg from "./assets/imgs/bg.png";
import voxo_project from "./assets/imgs/voxo_project.png";
import voxo_logo from "./assets/imgs/voxo_logo.png";
import cdl_project from './assets/imgs/cdl_project.png';
import cdl_logo from './assets/imgs/cdl_logo.png';
import The_Cypherverse_logo from './assets/imgs/The_Cypherverse_logo.png';

import twitter_p from './assets/icons/twitter_p.png';
import twitter_c from './assets/icons/twitter_c.png';
import twitter_w from './assets/icons/twitter_w.png';
import discord_p from './assets/icons/discord_p.png';
import discord_c from './assets/icons/discord_c.png';

import {tcvLinks} from './configs/constants'

function App() {
    return (
        <div className="App">
            <div className="main-layout">
                <div className="main-bg"
                     style={{position: 'absolute', width: '100vw', zIndex: 1, backgroundImage: `url(${bg})`}}>
                    <div className="main-gradient">
                        <div>
                            <div className="scroll-down5"></div>
                        </div>
                    </div>
                </div>
                <div style={{
                    width: '100%',
                    maxWidth: 1728,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    zIndex: 100
                }} className="default-layout">
                    <div>
                        <div style={{
                            width: '100%',
                            display: 'inline-flex',
                            justifyContent: 'space-between',
                            alignContent: 'center'
                        }}>
                            <a href={tcvLinks.default} rel="noreferrer">
                                <img className="main-logo" src={`${The_Cypherverse_logo}`} alt="Cypherverse"
                                     style={{zIndex: 300}}/>
                            </a>
                            <div style={{
                                height: '100%',
                                display: 'inline-flex',
                                alignContent: 'center',
                                alignSelf: 'center'
                            }}>
                                <a href={tcvLinks.twitterCypherverse} rel="noreferrer" target="_blank"
                                   style={{display: 'flex', alignContent: 'center', alignSelf: 'center'}}>
                                    <img src={`${twitter_w}`} alt="Twitter"
                                         style={{zIndex: 300, margin: 'auto'}}/>
                                </a>
                                <a href={tcvLinks.twitterCypherverse} rel="noreferrer" target="_blank"
                                   style={{display: 'flex', alignContent: 'center', alignSelf: 'center'}}>
                                    <p className="twitter-text">@TheCypherverse</p>
                                </a>
                            </div>
                        </div>
                        <div className="heading-padding">
                            <div className="default-text heading-width">
                                A world-class creative studio specialising in world-building, web3 gaming, and
                                innovative NFT offerings for our community.
                            </div>
                        </div>
                    </div>
                    <div className="project-heading-padding">
                        <div className="headline-text">OUR PROJECTS</div>
                    </div>
                    <div className="projects-box-before">
                        <div className="projects-box">
                            <div className="projects-logo-box">
                                <a href={tcvLinks.voxodeus} target="_blank"
                                   className="image-container image-container-img-voxo" style={{borderRadius: 6}}
                                   rel="noreferrer">
                                    <img src={`${voxo_project}`} alt="Voxo"/>
                                </a>
                                <a href={tcvLinks.voxodeus} target="_blank" className="image-container"
                                   rel="noreferrer">
                                    <img src={`${voxo_logo}`} className="voxo-logo-m " alt="Voxo logo"/>
                                </a>
                                <div className="project-text">
                                    VoxoDeus is the world’s first large-scale 3D-interactive collectible NFT. 6,312
                                    unique, scifi-mythic creatures, ranging from sentient robots to immortal cosmic
                                    beings. Also the first NFT project to partner with @TheSandboxGame to create
                                    playable avatars for VoxoDeus owners.
                                </div>
                                <div className="icons-row">
                                    <a href={tcvLinks.twitterVoxo} target="_blank" className="icon-btn icon-btn-voxo"
                                       rel="noreferrer">
                                        <img src={twitter_p} alt="Twitter"
                                             style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}/>
                                    </a>
                                    <a href={tcvLinks.discordVoxo} target="_blank" className="icon-btn  icon-btn-voxo"
                                       style={{marginLeft: 20}} rel="noreferrer">
                                        <img src={discord_p} alt="Twitter"
                                             style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}/>
                                    </a>
                                </div>
                            </div>
                            <div className="projects-logo-box right-project">
                                <a href={tcvLinks.cdl} target="_blank"
                                   className="image-container image-container-img-cdl" style={{borderRadius: 467}}
                                   rel="noreferrer">
                                    <img src={`${cdl_project}`} alt="Cosmic Dream Lounge"/>
                                </a>
                                <a href={tcvLinks.cdl} target="_blank" className="image-container" rel="noreferrer">
                                    <img src={`${cdl_logo}`} className="cdl-logo-m "
                                         alt="Cosmic Dream Lounge Logo"/>
                                </a>
                                <div className="project-text cdl-text-m">
                                    Cosmic Dream Lounge is a cozy space cruise set on ‘The VHS Arcadia’. A rocket ship
                                    full of elves, drifting through the cosmos and searching for adventure. It’s an
                                    escapist journey with an unknown destination. A place to find whatever makes you
                                    feel better along the way.
                                </div>
                                <div className="icons-row">
                                    <a href={tcvLinks.twitterCdl} target="_blank" className="icon-btn  icon-btn-cdl"
                                       rel="noreferrer">
                                        <img src={twitter_c} alt="Twitter"
                                             style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}/>
                                    </a>
                                    <a href={tcvLinks.discordCdl} target="_blank" className="icon-btn  icon-btn-cdl"
                                       style={{marginLeft: 20}} rel="noreferrer">
                                        <img src={discord_c} alt="Twitter"
                                             style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-box" style={{zIndex: 500}}>
                <p className="footer-text">©The Cypherverse ‘22</p>
                <div style={{display: 'inline-block'}}>
                    <a className="footer-text footer-text-link" style={{marginLeft: 10, cursor: 'pointer'}}
                       href={tcvLinks.privacy} target="_blank" rel="noreferrer">Privacy</a>
                </div>
            </div>
        </div>
    );
}

export default App;
